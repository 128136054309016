/**
 * @file It contains scripts for messages view
 */
import { mapGetters, mapActions } from "vuex"
import { addDays } from "@/utils"
import ThemisFileComponent from "@/components/shared/file"
import ThemisPopOver from "@/components/shared/pop-over"
import ThemisReplyMessageCard from "@/components/reply-message-card"

export default {
  name      : "Messages",
  components: {
    ThemisFileComponent,
    ThemisPopOver,
    ThemisReplyMessageCard
  },
  data() {
    return {
      showOriginalMessage      : undefined,
      isAttachmentDialogVisible: false,
      textMessage              : undefined
    }
  },
  methods: {
    ...mapActions({
      downloadFile     : "messageItems/downloadFile",
      uploadAttachments: "messageItems/uploadAttachments",
      removeAttachment : "messageItems/removeAttachment",
      sendReport       : "reports/sendReport",
      notify           : "shared/notify",
      addMessage       : "messages/addMessage",
      updateMessage    : "messages/updateMessage"
    }),
    handleClick() {
      const caller    = arguments[0]
      const messageId = arguments[1]
      if (caller === "message" && !this.isAttachmentDialogVisible) {
        if (caller === "message" && this.selectedMessage?.id === messageId) {
          this.$router.push({
            name  : "messages",
            params: {
              locale : this.$route.params.locale,
              channel: this.$route.params.channel
            },
            query: { selectedMessage: undefined }
          })
        } else {
          this.$router.push({
            name  : "messages",
            params: {
              locale : this.$route.params.locale,
              channel: this.$route.params.channel
            },
            query: { selectedMessage: messageId }
          })
        }
      }
    },
    async saveAttachments(files) {
      if(this.lastReporterDraftMessage) {
        this.uploadAttachments({
          id   : +this.lastReporterDraftMessage.id,
          files: files
        })
      } else {
        await this.addMessage({
          reportId: this.report.id
        })
        this.uploadAttachments({
          id   : +this.lastReporterDraftMessage.id,
          files: files
        })
      }
    },
    notifyFileSizeLimitError(file) {
      this.notify({
        type: "error",
        text: this.$t("122", {
          name: file.name
        }),
        icon: "mdi-alert-circle"
      })
    },
    async createMessage() {
      if(!this.lastReporterDraftMessage) {
        await this.addMessage({
          reportId: this.report.id
        })
      }
    },
    sendMessage(textMessage) {
      this.sendReport({
        messageId : this.lastReporterDraftMessage.id,
        value     : textMessage,
        languageId: this.report.languageId,
        type      : this.$CONSTANTS.MESSAGE_ITEM_TYPE.TEXT })
    },
    notifyTextMessageLimitReached() {
      this.notify({
        type: "warning",
        text: "52",
        icon: "mdi-alert-circle"
      })
    }
  },
  computed: {
    ...mapGetters({
      reporterLanguage      : "auth/reporterLanguage",
      report                : "auth/report",
      messages              : "messages/messages",
      messageItems          : "messageItems/messageItems",
      languages             : "languages/languages",
      channels              : "channels/channels",
      isUploadingAttachments: "messageItems/isUploadingAttachments",
      isSendingReport       : "reports/isSendingReport",
      isReportSent          : "reports/isReportSent"
    }),
    languagesMap() {
      const languagesMap = new Object()
      for (const language of this.languages) {
        languagesMap[language.id] = language
      }
      return languagesMap
    },
    channel() {
      return this.channels.find(channel => channel.name === this.$route.params.channel)
    },
    messagesForDisplay() {
      const messages = new Array()
      for (const message of this.messages) {
        if (message.status !== this.$CONSTANTS.MESSAGE_STATUS.DRAFT) {
          const textMessageItem = this.messageItems.find(messageItem =>
            messageItem?.messageId === message.id &&
            messageItem?.type === this.$CONSTANTS.MESSAGE_ITEM_TYPE.TEXT &&
            messageItem?.languageId === this.report?.languageId
          )

          const attachmentMessageItems = this.messageItems.filter(messageItem =>
            messageItem?.messageId === message.id &&
            ![
              this.$CONSTANTS.MESSAGE_ITEM_TYPE.TEXT,
              this.$CONSTANTS.MESSAGE_ITEM_TYPE.AUDIO
            ].includes(messageItem?.type)
          )

          if (textMessageItem) {
            messages.push({
              id     : message.id,
              isReply: !!message.userId,
              status : message.status,
              textMessageItem,
              attachmentMessageItems
            })
          }
        }
      }
      return messages.sort((left, right) => new Date(right.textMessageItem.createdAt) -
        new Date(left.textMessageItem.createdAt))
    },
    isMobileView() {
      return this.$vuetify.breakpoint.smAndDown
    },
    translatedMessages(){
      let translatedMessages
      let translatedMessageItem
      let originalMessageItem
      if(this.selectedMessage.userId !== null) {
        const messageItemsOfSelectedMessage = this.messageItems?.filter(messageItem =>
          messageItem.messageId === this.selectedMessage.id
          && messageItem.type === this.$CONSTANTS.MESSAGE_ITEM_TYPE.TEXT)
        if(messageItemsOfSelectedMessage.length === 1){
          const originalMessageItem = messageItemsOfSelectedMessage[0]
          translatedMessages        = {
            id                 : messageItemsOfSelectedMessage[0].id,
            isMessageTranslated: false,
            originalMessageItem
          }
        } else if(messageItemsOfSelectedMessage.length === 2){
          translatedMessageItem = messageItemsOfSelectedMessage?.find(messageItem =>
            messageItem.processedFromId !== null)
          originalMessageItem   = messageItemsOfSelectedMessage?.find(messageItem =>
            messageItem.processedFromId === null)
          translatedMessages    = {
            id                 : messageItemsOfSelectedMessage[0].id,
            isMessageTranslated: true,
            originalMessageItem,
            translatedMessageItem
          }
        }
      }
      return translatedMessages
    },
    selectedMessage() {
      const message = this.messagesForDisplay?.find(message => message.id === +this.$route.query.selectedMessage)
      return message
    },
    isReportClosed() {
      return this.report && this.report.status === this.$CONSTANTS.REPORT_STATUS.CLOSED
    },
    numberOfDaysToDisableReport() {
      const currentDate      = new Date()
      const reportClosedDate = addDays(new Date(this.report.closedAt),
        this.$CONSTANTS.NUMBER_OF_DAYS_TO_DISABLE_REPORT_LOGIN)
      return Math.floor(( reportClosedDate.valueOf() - currentDate.valueOf() ) / this.$CONSTANTS.DAY_IN_MILLI_SECONDS)
    },
    isLastMessageInListIsFromClient() {
      return this.messagesForDisplay[0]?.isReply
    },
    isLastMessageFromClientIsUnread() {
      if(this.messagesForDisplay[0]?.isReply && this.messagesForDisplay[0].status ===
        this.$CONSTANTS.MESSAGE_STATUS.NEW){
        return true
      } else {
        return false
      }
    },
    lastReporterDraftMessage() {
      for(const message of this.messages) {
        if (!message.userId && message.status ===  this.$CONSTANTS.MESSAGE_STATUS.DRAFT) {
          return message
        }
      }
    },
    messageItemsOfLastReporterDraftMessage() {
      return this.messageItems.filter(messageItem => messageItem.messageId === this.lastReporterDraftMessage?.id)
    },
    messageForReplyMessageCard() {
      if (this.lastReporterDraftMessage) {
        return {
          ...this.lastReporterDraftMessage,
          messageItems: this.messageItemsOfLastReporterDraftMessage
        }
      }
    }
  },
  watch: {
    isReportSent: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "55"
          })
        }
      }
    },
    selectedMessage: {
      handler: function(newValue) {
        if (newValue) {
          if (newValue.isReply && newValue.status === this.$CONSTANTS.MESSAGE_STATUS.NEW) {
            this.updateMessage({
              id    : newValue.id,
              status: this.$CONSTANTS.MESSAGE_STATUS.SEEN
            })
          }
        }
      }
    }
  }
}
